// eslint-disable-next-line no-restricted-imports
import { getFullStory } from '@r-client/shared/util/core';
import { getIgnoredMessages, HOST_BLOCK_LIST, isIgnoredByFilename, isIgnoredByHost } from './filters';
export var createReportingConfig = function createReportingConfig(settings) {
  // see https://docs.rollbar.com/docs/rollbarjs-configuration-reference
  return {
    accessToken: settings.accessToken,
    captureUncaught: true,
    captureUnhandledRejections: true,

    /** Filters items by `filename` in `body.trace_chain` or `body.trace` */
    hostBlockList: HOST_BLOCK_LIST,
    ignoredMessages: getIgnoredMessages(settings.environment),
    checkIgnore: function checkIgnore() {
      return isIgnoredByHost.apply(void 0, arguments) || isIgnoredByFilename.apply(void 0, arguments);
    },
    payload: {
      environment: settings.environment,
      client: {
        javascript: {
          code_version: settings.codeVersion,
          source_map_enabled: true
        }
      },
      server: {
        host: settings.serverHost
      }
    },
    transform: function transform(payload) {
      // Always add FullStory URL to the payload
      var fs = getFullStory();
      var fullStoryUrl = fs === null || fs === void 0 ? void 0 : fs.getCurrentSessionURL(true);

      if (fullStoryUrl) {
        payload.fullStoryUrl = fullStoryUrl;
      }
    }
  };
};